<template>
  <div class="parent">



    <!--------------- General website section -------------------------->
    <vx-card class="accordin-card mt-5 legals">
      <vs-collapse accordion>
        <vs-collapse-item ref="productFieldCollapse" open>
          <div slot="header">
            <h3 class="font-normal text-base sm:text-lg md:text-xl lg:text-2xl">General website terms</h3>
          </div>

          <div class="legals__item">
            <div
              class="mb-6 flex flex-row items-center"
              :class="{ 'justify-end': generalWebsiteTerms.length > 0 }"
            >
              <vs-button
                v-round
                class="w-2/12"
                v-if="generalWebsiteTerms.length === 0"
                color="primary"
                icon="add"
                @click="createLegal('GENERAL-WEBSITE-TERMS')"
              >New Document</vs-button
              >

              <vs-button
                v-else
                class="only-border-btn"
                v-round
                size="medium"
                @click="createLegal('GENERAL-WEBSITE-TERMS', true)"
              ><plus-icon size="1.5x" class="button-icon"></plus-icon>New
                Version</vs-button
              >
            </div>
            <vs-table :data="generalWebsiteTerms" v-if="generalWebsiteTerms.length > 0">
              <template slot="thead">
                <vs-th class="w-2/12 legals-th"> Document Name </vs-th>
                <vs-th class="w-2/12 legals-th"> Date Created </vs-th>
                <vs-th class="legals-th"> Version</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].email">
                    {{ data[indextr].documentName }}
                  </vs-td>

                  <vs-td :data="data[indextr].createdAt">
                    {{ formatScheduleDate(data[indextr].createdAt) }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].version }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <span class="active-badge" v-if="indextr === 0"
                    >Active</span
                    >
                  </vs-td>
                  <vs-td :data="data[indextr].id" class="view-btn">
                    <a class="underline" @click="viewLegal(tr._id)">view</a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>




    <!--------------- merchant onboarding section -------------------------->
    <vx-card class="accordin-card mt-5 legals">
      <vs-collapse accordion class="p-0">
        <vs-collapse-item ref="productFieldCollapse" class="p-0" open>
          <div slot="header">
            <h3 class="font-normal text-base sm:text-lg md:text-xl lg:text-2xl">Merchant onboarding terms & conditions</h3>
          </div>

          <div class="legals__item">
            <div
              class="mb-6 flex flex-row items-center"
              :class="{ 'justify-end': merchantOnBoardingTerms.length > 0 }"
            >
              <vs-button
                v-round
                class="w-2/12"
                v-if="merchantOnBoardingTerms.length === 0"
                color="primary"
                icon="add"
                @click="createLegal('MERCHANT-ONBOARDING-T&C')"
                >New Document</vs-button
              >

              <vs-button
                v-else
                class="only-border-btn"
                v-round
                size="medium"
                @click="createLegal('MERCHANT-ONBOARDING-T&C', true)"
                ><plus-icon size="1.5x" class="button-icon"></plus-icon>New
                Version</vs-button
              >
            </div>
            <vs-table
              :data="merchantOnBoardingTerms"
              v-if="merchantOnBoardingTerms.length > 0"
            >
              <template slot="thead">
                <vs-th class="w-2/12 legals-th"> Document Name </vs-th>
                <vs-th class="w-2/12 legals-th"> Date Created </vs-th>
                <vs-th class="legals-th"> Version</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].email">
                    {{ data[indextr].documentName }}
                  </vs-td>

                  <vs-td :data="data[indextr].createdAt">
                    {{ formatScheduleDate(data[indextr].createdAt) }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].version }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <span class="active-badge" v-if="indextr === 0"
                      >Active</span
                    >
                  </vs-td>
                  <vs-td :data="data[indextr].id" class="view-btn">
                    <a class="underline" @click="viewLegal(tr._id)">view</a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>



    <!--------------- Privacy policy section -------------------------->
    <vx-card class="accordin-card mt-5 legals">
      <vs-collapse accordion>
        <vs-collapse-item ref="productFieldCollapse" open>
          <div slot="header">
            <h3 class="font-normal text-base sm:text-lg md:text-xl lg:text-2xl">Privacy policy</h3>
          </div>

          <div class="legals__item">
            <div
              class="mb-6 flex flex-row items-center"
              :class="{ 'justify-end': privacy.length > 0 }"
            >
              <vs-button
                v-round
                class="w-2/12"
                v-if="privacy.length === 0"
                color="primary"
                icon="add"
                @click="createLegal('PRIVACY-POLICY')"
                >New Document</vs-button
              >

              <vs-button
                v-else
                class="only-border-btn"
                v-round
                size="medium"
                @click="createLegal('PRIVACY-POLICY', true)"
                ><plus-icon size="1.5x" class="button-icon"></plus-icon>New
                Version</vs-button
              >
            </div>
            <vs-table :data="privacy" v-if="privacy.length > 0">
              <template slot="thead">
                <vs-th class="w-2/12 legals-th"> Document Name </vs-th>
                <vs-th class="w-2/12 legals-th"> Date Created </vs-th>
                <vs-th class="legals-th"> Version</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
                <vs-th class="legals-th">&nbsp;</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td :data="data[indextr].email">
                    {{ data[indextr].documentName }}
                  </vs-td>

                  <vs-td :data="data[indextr].createdAt">
                    {{ formatScheduleDate(data[indextr].createdAt) }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    {{ data[indextr].version }}
                  </vs-td>

                  <vs-td :data="data[indextr].id">
                    <span class="active-badge" v-if="indextr === 0"
                      >Active</span
                    >
                  </vs-td>
                  <vs-td :data="data[indextr].id" class="view-btn">
                    <a class="underline" @click="viewLegal(tr._id)">view</a>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>
  </div>
</template>
<script>
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters, mapActions } from "vuex";
import { PlusIcon } from "vue-feather-icons";
import _ from "lodash";
import moment from "moment";
export default {
  components: {
    PlusIcon,
  },
  data() {
    return {
      toggleFlex: false,
      pageNumber: 1,
      totalData: 0,
      limit: 5,
      active: false,
      formData: {
        name: "",
      },
    };
  },
  methods: {
    ...mapActions("admin", ["checkLogin"]),
    viewLegal(id) {
      const routeData = this.$router.resolve({
        name: "terms-view",
        params: { id },
        query: { type: "DASHBOARD" },
      });

      window.open(routeData.href, '_blank');
    },
    createLegal(type, newVersion = false) {
      this.$router.push({
        name: "legals-editor",
        params: { type },
        query: { newVersion },
      });
    },
    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    getLegals() {
      this.$vs.loading();
      this.$store
        .dispatch("legals/fetchAllLegals", "DASHBOARD")
        .then((result) => {
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: "Error",
            text: "Error fetching legals",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },

    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  created() {
    this.getLegals();
    this.checkAdminLogin();
  },
  computed: {
    ...mapState("legals", ["legals"]),
    ...mapGetters("legals", ["getLegalByType"]),

    privacy() {
      const terms = this.legals.filter(
        (legal) => legal.type === "PRIVACY-POLICY"
      );
      return _.sortBy(terms, ["createdAt"]).reverse();
    },
    generalWebsiteTerms() {
      const terms = this.legals.filter(
        (legal) => legal.type === "GENERAL-WEBSITE-TERMS"
      );
      return _.sortBy(terms, ["createdAt"]).reverse();
    },
    merchantOnBoardingTerms() {
      const terms = this.legals.filter(
        (legal) => legal.type === "MERCHANT-ONBOARDING-T&C"
      );
      return _.sortBy(terms, ["createdAt"]).reverse();
    },
  },
};
</script>
<style lang="scss">
@mixin font($weight, $size) {
  font-weight: $weight;
  font-size: $size !important;
}
</style>
